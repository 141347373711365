<template>
  <div class="contents-wrap">
<!--    <div class="sub_title_txt pb-3">
      <h2>문항 상세 정보</h2>
    </div>-->

    <div class="flex flex-col justify-between">
      <div>
        <table class="tableList">
          <colgroup>
            <col style="width:20%"/>
            <col style="width:25%"/>
            <col style="width:20%"/>
            <col style="width:25%"/>
          </colgroup>
          <tbody>
          <tr>
            <th scope="row" class="p8">
              <label for="label5">
                <span>문항구분</span>
                <span class="icon_require">필수항목</span>
              </label>
            </th>
            <td class="clearfix p8">
              <DxSelectBox
                  placeholder="선택"
                  display-expr="codeNm"
                  value-expr="codeId"
                  :width="135"
                  :styling-mode="stylingMode"
                  :items="getDivisionType"
                  v-model="questionDivisionCd"
                  @selection-changed="e => onSelectionChanged(e)"
              >
                <DxValidator validation-group="validationGroupName">
                  <DxRequiredRule message="문항구분은 필수입니다."/>
                </DxValidator>
              </DxSelectBox>
            </td>
            <th scope="row" class="p8">
              <label for="label5">
                <span>문항구분상세</span>
                <span class="icon_require">필수항목</span>
              </label>
            </th>
            <td class="p8">
              <DxSelectBox
                  placeholder="선택"
                  :width="135"
                  display-expr="codeNm"
                  value-expr="codeId"
                  :styling-mode="stylingMode"
                  :items="formData.divisionDetailCdList"
                  v-model="questionDivisionDetailCd"
              >
                <DxValidator validation-group="validationGroupName">
                  <DxRequiredRule message="문항구분상세는 필수입니다."/>
                </DxValidator>
              </DxSelectBox>
            </td>
          </tr>
          <tr>
            <th scope="row" class="p8">
              <label for="label5">
                <span>난이도</span>
                <span class="icon_require">필수항목</span>
              </label>
            </th>
            <td class="clearfix p8">
              <DxSelectBox
                  placeholder="난이도 선택"
                  display-expr="label"
                  value-expr="value"
                  :width="135"
                  :styling-mode="stylingMode"
                  :items="ewmCodes.examLevel.values"
                  v-model="examLevel"
              >
                <DxValidator validation-group="validationGroupName">
                  <DxRequiredRule message="난이도는 필수입니다."/>
                </DxValidator>
              </DxSelectBox>
            </td>
            <th scope="row" class="p8">
              <label for="label5">
                <span>정답유형</span>
                <span class="icon_require">필수항목</span>
              </label>
            </th>
            <td class="p8">
              <DxSelectBox
                  placeholder="정답유형 선택"
                  display-expr="label"
                  value-expr="value"
                  :width="135"
                  :styling-mode="stylingMode"
                  :items="ewmCodes.answerType.values"
                  v-model="answerType"
                  @value-changed="onChangeAnswerType"
              >
                <DxValidator validation-group="validationGroupName">
                  <DxRequiredRule message="정답유형은 필수입니다."/>
                </DxValidator>
              </DxSelectBox>
            </td>
          </tr>
          <tr>
            <th scope="row" class="p8">
              <label for="label5">
                <span>사용여부</span>
                <span class="icon_require">필수항목</span>
              </label>
            </th>
            <td class="clearfix p8">
              <DxSelectBox
                  placeholder="사용여부 선택"
                  display-expr="label"
                  value-expr="value"
                  :width="135"
                  :height="30"
                  :styling-mode="stylingMode"
                  :items="commonCodes.stringUsedFlag.values"
                  v-model="useFl"
              >
                <DxValidator validation-group="validationGroupName">
                  <DxRequiredRule message="사용여부는 필수입니다."/>
                </DxValidator>
              </DxSelectBox>
            </td>
            <th scope="row" class="p8">
              <label for="label5">
                <span>채첨방식</span>
                <span class="icon_require">필수항목</span>
              </label>
            </th>
            <td>
              <DxSelectBox
                  placeholder="채점방식 선택"
                  display-expr="label"
                  value-expr="value"
                  :width="135"
                  :height="30"
                  :styling-mode="stylingMode"
                  :items="ewmCodes.examScoringType.values"
                  v-model="examScoringType"
              >
                <DxValidator validation-group="validationGroupName">
                  <DxRequiredRule message="채점방식은 필수입니다."/>
                </DxValidator>
              </DxSelectBox>
            </td>
          </tr>
          <tr>
            <th scope="row"  class="p8">
              <label for="label5">
                <span>첨부파일</span>
              </label>
            </th>
            <td class="p8">
<!--              <DxTextBox
                  id="dropzone-external"
                  :width="135"
                  :styling-mode="stylingMode"
                  v-model="originalFileName"
              >
              </DxTextBox>-->
              <div :v-show="this.originalFileName">{{ this.originalFileName }}</div>
              <DxButton
                  text="파일추가"
                  :visible="!this.originalFileName"
                  type="button"
                  @click="uploadFiles"
                  class="btn_XS white light_filled"
                  :height="15"
              />
              <DxFileUploader
                  ref="fileUploader"
                  id="file-uploader"
                  :multiple="false"
                  upload-mode="useButtons"
                  :visible="false"
                  @value-changed="onChangedAttachedFiles"
              />
            </td>

            <th scope="row" class="p8">
              <label for="label5">
                <span>배점</span>
              </label>
            </th>
            <td class="p8">
<!--              <DxNumberBox :width="135" :read-only="true" :styling-mode="stylingMode" v-model="scroe">
              </DxNumberBox>-->
              <span>{{this.scroe}}</span>
            </td>
          </tr>
          <tr>
            <th scope="row" class="p8">
              <label for="label5">
                <span>문항내용</span>
                <span class="icon_require">필수항목</span>
              </label>
            </th>
            <td colspan="3" class="p8">
              <DxHtmlEditor
                  :height="140"
                  :styling-mode="stylingMode"
                  v-model="qustionNm"
              >
                <DxToolbar>
                  <DxItem name="undo"/>
                  <DxItem name="redo"/>
                  <DxItem name="font"/>
                  <DxItem name="separator"/>
                  <DxItem name="header"/>
                  <DxItem name="separator"/>
                  <DxItem name="bold"/>
                  <DxItem name="italic"/>
                  <DxItem name="strike"/>
                  <DxItem name="underline"/>
                  <DxItem name="separator"/>
                  <DxItem name="image"/>
                  <DxItem name="separator"/>
                  <DxItem name="insertTable"/>
                </DxToolbar>
                <DxValidator validation-group="validationGroupName">
                  <DxRequiredRule message="문항내용은 필수입니다."/>
                </DxValidator>
              </DxHtmlEditor>
            </td>
          </tr>
          <tr>
            <th scope="row" class="p8">
              <label for="label5">
                <span>정답해설</span>
              </label>
            </th>
            <td colspan="3" class="p8">
              <DxTextBox width="100%" :styling-mode="stylingMode" v-model="description"/>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="page-bin mar_b10"></div>

        <div class="sub_title_txt clearfix">
          <div class="fr">
            <DxButton
                text="보기추가"
                :disabled="questionCnt > 0"
                :visible="true"
                type="button"
                :height="30"
                class="btn_XS white light_filled add2"
                @click="addAnswer"
            />
          </div>
        </div>

        <div class="page-bin mar_b10"></div>

        <div style="min-height: 280px;">
          <table class="tableList">
            <colgroup>
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead class="inner-header">
            <tr>
              <th scope="col" class="p10_20">No</th>
              <th scope="col" style="width: 50%;">
                <span>보기명</span>
                <span class="icon_require">필수항목</span>
              </th>
              <th scope="col" class="p10_20">
                <span>배점</span>
                <span class="icon_require">필수항목</span>
              </th>
              <th scope="col" class="p10_20"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(detail, index) in details" :key="detail.detailId">
              <td class="p10_20">{{ detail.questionNumber }}</td>
              <td style="width: 50%;">
                <DxTextBox
                    placeholder="보기를 입력해주세요."
                    class="mar_ri10"
                    :styling-mode="stylingMode"
                    v-model="detail.detailDescription"
                >
                  <DxValidator validation-group="validationGroupName">
                    <DxRequiredRule message="보기는 필수입니다."/>
                  </DxValidator>
                </DxTextBox>
              </td>
              <td class="p10_20">
                <DxNumberBox
                    placeholder="점수를 입력해주세요."
                    class="mar_ri10"
                    :styling-mode="stylingMode"
                    v-model="detail.score"
                    @value-changed="onScoreKeyUp"

                >
                  <DxValidator validation-group="validationGroupName">
                    <DxRequiredRule message="배점은 필수입니다."/>
                  </DxValidator>
                </DxNumberBox>
              </td>
              <td class="p10_20">
                <DxButton
                    text=""
                    class="btn-icon trash dx-button dx-button-normal dx-button-mode-contained dx-widget"
                    :disabled="questionCnt > 0"
                    @click="onDeleteAnswerRow(index)"
                />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="text-center mt-6">
        <DxButton
            text="저 장"
            :disabled="questionCnt > 0"
            :width="120"
            :height="40"
            class="btn_XS default filled"
            style="margin-right: 10px"
            @click="onSubmitForm"
        />
        <DxButton text="닫 기" :width="120" :height="40" class="btn_XS white light_filled" @click="onCloseForm"/>
      </div>
    </div>
  </div>
</template>

<script>
import {DxFileUploader} from 'devextreme-vue/file-uploader';
import {DxTextBox} from 'devextreme-vue/text-box';
import {DxNumberBox} from 'devextreme-vue/number-box';
import {DxSelectBox} from 'devextreme-vue/select-box';
import {DxValidator, DxRequiredRule} from 'devextreme-vue/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import {DxButton} from 'devextreme-vue/button';
import {DxHtmlEditor, DxToolbar, DxItem} from 'devextreme-vue/html-editor';
import {isSuccess} from '@/plugins/common-lib';

let vm = this;
export default {
  components: {
    DxTextBox,
    DxSelectBox,
    DxFileUploader,
    DxHtmlEditor,
    DxToolbar,
    DxItem,
    DxButton,
    DxRequiredRule,
    DxValidator,
    DxNumberBox,
  },
  props: {},
  watch: {},
  data() {
    return {
      editorValue: '<p>Content with an image</p>',
      codeMap: {},
      questionDivisionCdDataSource: [],
      questionDivisionDetailCdDataSource: [],
      attacheFiles: null,
      questionId: null,
      questionDivisionCd: null,
      questionDivisionDetailCd: null,
      qustionNm: null,
      fileGroupId: null,
      levelCd: null,
      examLevel: null,
      answerTypeCd: null,
      answerType: null,
      viewCd: null,
      viewFl: null,
      useFl: null,
      scoringTypeCd: null,
      examScoringType: null,
      originalFileName: null,
      scroe: 0, // 총점
      description: null,
      originalName: null,
      questionCnt: 0,
      stylingMode: 'outlined',
      formData: {
        divisionDetailCdList: null,
        originalFileName: null,
        uploadFile: null,
        scroe: 0,
        questionDivisionDetailCd: null,
      },
      details: [],
    };
  },
  computed: {
    commonCodes() {
      return this.$_enums.common;
    },
    ewmCodes() {
      return this.$_enums.ewm;
    },
    getDivisionType() {
      //return this.$_getCode('ewm_edu_exam_question_division');
      return this.questionDivisionCdDataSource;
    },
  },
  created() {
    this.createdData();
  },
  mounted() {
    this.mountData();
  },
  methods: {
    uploadFiles() {
      const { questionCnt } = vm;
      if(questionCnt > 0) {
        this.$_Msg('출제횟수가 있는 문항에 대해서는 삭제 및 수정이 불가능 합니다.');
        return
      }
      this.$refs.fileUploader.instance._selectButtonClickHandler();
    },
    async onSubmitForm() {
      const validationResult = validationEngine.validateGroup('validationGroupName');
      if (!validationResult.isValid) {
        return;
      }
      if (vm.questionCnt > 0) {
        this.$_Msg('출제횟수가 있는 문항에 대해서는 삭제 및 수정이 불가능 합니다.');
        return;
      }

      let fileGroupId = null;
      let originalFileName = null;
      if (vm.attacheFiles) {
        const uploadedFiles = await this.onUploadedAttachedFile(vm.attacheFiles);
        fileGroupId = uploadedFiles.data?.data[0].fileGroupId;
        originalFileName = uploadedFiles.data?.data[0].originalFileName;

        vm.fileGroupId = fileGroupId;
        vm.originalFileName = originalFileName;
      }

      const answerType = vm.answerType;
      const answerSize = vm.details?.length || 0;

      // 객관식: 5개까지 등록가능
      //if(answerTypeCd == 994 && answerSize == 5) {
      //	this.$_Msg("객관식은 5개까지 등록가능합니다.");
      //	return false;
      //}
      const limitAnswerSize = this.getLimitAnswerSize();

      if (answerSize < 1) {
        this.$_Msg('보기는 최소 1개 이상 등록되어야 합니다.');
        return false;
      }
      // OX 보기 2개까지 등록가능
      if (answerType === this.$_enums.ewm.answerType.OX.value && answerSize != limitAnswerSize) {
        this.$_Msg(`정답유형 OX는 보기 ${limitAnswerSize}개를 등록 해야 합니다.`);
        return false;
      }

      // 단단형, 서술형 보기 한개만 등록가능
      if (
          (answerType === this.$_enums.ewm.answerType.SHORT.value || answerType === this.$_enums.ewm.answerType.LONG.value) &&
          answerSize != limitAnswerSize
      ) {
        this.$_Msg(`정답유형 단답형, 서술형은 보기 ${limitAnswerSize}개를 등록 해야 합니다.`);
        return false;
      }

      vm.getSumScore();

      if (!(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {title: `<h2>알림</h2>`}))) {
        return;
      }

      let params = {
        questionId: vm.questionId,
        questionDivisionCd: vm.questionDivisionCd,
        questionDivisionDetailCd: vm.questionDivisionDetailCd,
        qustionNm: vm.qustionNm,
        fileGroupId: fileGroupId,
        levelCd: vm.levelCd,
        examLevel: vm.examLevel,
        answerTypeCd: vm.answerTypeCd,
        answerType: vm.answerType,
        viewCd: vm.viewCd,
        viewFl: vm.viewFl,
        useFl: vm.useFl,
        scoringTypeCd: vm.scoringTypeCd,
        examScoringType: vm.examScoringType,
        originalFileName: vm.originalFileName,
        scroe: vm.scroe, // 총점
        description: vm.description,
        originalName: originalFileName,
        details: vm.details,
      };

      const payload = {
        actionname: 'EWM_EXAM_QUESTION_MERGE',
        data: params,
        loading: false,
      };

      const res = await this.CALL_EWM_API(payload);

      if (isSuccess(res)) {
        this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
        vm.onCloseForm();
      } else {
        this.$_Msg(`데이터 저장 실패 \n 원인 : [${res.data?.header?.resMsg ?? 'HTTP 에러'}]`);
      }
    },
    initForm() {
      vm.questionId = null;
      vm.questionDivisionCd = null;
      vm.questionDivisionDetailCd = null;
      vm.qustionNm = null;
      vm.levelCd = null;
      vm.examLevel = null;
      vm.answerTypeCd = null;
      vm.answerType = null;
      vm.viewCd = null;
      vm.viewFl = this.$_enums.common.stringUsedFlag.YES.value;
      vm.useFl = this.$_enums.common.stringUsedFlag.NO.value;
      vm.scoringTypeCd = null;
      vm.scoringType = null;
      vm.originalFileName = null;
      vm.scroe = 0; // 총점
      vm.description = null;
      vm.originalName = null;
      vm.fileGroupId = null;
      vm.details = [];
      vm.formData.divisionDetailCdList = null;
      vm.questionCnt = 0;
      vm.attacheFiles = null;

      validationEngine.resetGroup('validationGroupName');
    },
    loadFormData(rowdata, detailInfo) {
      vm.details = detailInfo;
      vm.questionId = rowdata?.id;
      vm.qustionNm = rowdata?.qustionNm;
      vm.levelCd = rowdata?.levelCd;
      vm.examLevel = rowdata?.examLevel;
      vm.answerTypeCd = rowdata?.answerTypeCd;
      vm.answerType = rowdata?.answerType;
      vm.viewCd = rowdata?.viewCd;
      vm.viewFl = rowdata?.viewFl;
      vm.useFl = rowdata?.useFl;
      vm.scoringTypeCd = rowdata?.scoringTypeCd;
      vm.examScoringType = rowdata?.examScoringType;
      vm.originalFileName = rowdata?.originalFileName;
      vm.scroe = rowdata?.scroe; // 총점
      vm.description = rowdata?.description;
      vm.originalName = rowdata?.originalName;
      vm.fileGroupId = rowdata?.fileGroupId;
      vm.questionCnt = rowdata?.questionCnt;
      vm.questionDivisionCd = rowdata?.questionDivisionCd;

      this.onChangeDivisionType(vm.questionDivisionCd).then(() => {
        vm.questionDivisionDetailCd = rowdata?.questionDivisionDetailCd;
      });
    },
    onCloseForm() {
      this.initForm();
      this.$emit('onCloseAnswerForm');
      this.$emit('onSelectList');
    },
    getSumScore() {
      let sum = 0;
      const details = vm.details || [];
      for (let value of details) {
        sum += Number(value.score);
      }
      vm.scroe = sum;
    },
    async onDeleteAnswerRow(idx) {
      if (vm.questionCnt > 0) {
        this.$_Msg('출제횟수가 있는 문항에 대해서는 삭제 및 수정이 불가능 합니다.');
        return;
      }
      // row 삭제
      vm.details.splice(idx, 1);
      vm.details.some((val, idx) => {
        vm.details[idx].questionNumber = idx + 1;
      });

      vm.getSumScore();
    },
    onScoreKeyUp() {
      this.getSumScore();
    },
    getLimitAnswerSize() {
      // 정답유형별 row개수 제한
      // -- 1. 서술형, 단답형 1개까지 입력가능
      // -- 2. OX 2개까지 입력가능
      // -- 3. 객관식 5개까지 입력가능

      let limitAnswerSize = 0;
      // 객관식 5개까지 등록가능
      if (this.answerType === this.$_enums.ewm.answerType.SHORT.value || this.answerType === this.$_enums.ewm.answerType.LONG.value) {
        limitAnswerSize = 1;
      } else if (this.answerType === this.$_enums.ewm.answerType.OX.value) {
        limitAnswerSize = 2;
      } else {
        limitAnswerSize = 5;
      }
      return limitAnswerSize;
    },
    addAnswer() {
      const answerSize = vm.details?.length || 0;

      const limitAnswerSize = this.getLimitAnswerSize();

      if (answerSize + 1 > limitAnswerSize) {
        this.$_Msg(`본 정답유형은 ${limitAnswerSize}개까지 등록 가능합니다.`);
        return false;
      }

      let length = answerSize;
      const addIndex = ++length;
      let questionNumber = {
        questionNumber: addIndex,
      };
      if (answerSize == 0) {
        vm.details = [];
      }

      vm.details?.push(questionNumber);
    },

    onChangeAnswerType() {
      const limitAnswerSize = this.getLimitAnswerSize();

      if (vm.details?.length && vm.details.length > limitAnswerSize) {
        while (limitAnswerSize < vm.details.length) {
          this.onDeleteAnswerRow(limitAnswerSize);
        }
      }
    },

    onChangedAttachedFiles(e) {
      console.log("e :", e)
      if (vm.questionCnt > 0) {
        this.$_Msg('출제횟수가 있는 문항에 대해서는 삭제 및 수정이 불가능 합니다.');
        return;
      }
      if (e.value.length > 1) {
        this.$_Msg('한 개의 이미지 파일을 올려주세요.');
        return false;
      }
      vm.attacheFiles = e.value;
      vm.originalFileName = e.value[0].name;
    },

    async onUploadedAttachedFile(files) {
      const file = files[0];
      vm.attacheFiles = files;
      vm.formData.uploadFile = files[0];
      vm.formData.originalFileName = files[0].name;

      let formData = new FormData();
      formData.append('file', file);

      let payload = {
        actionname: 'COMMON_ATTACHED_FILE_UPLOAD',
        data: formData,
        loading: false,
      };

      try {
        let res = await this.CALL_API(payload);
        if (res.status === 200) {
          //const fileGroupId = res.data?.data[0].fileGroupId;
          //vm.fileGroupId = fileGroupId;
          //vm.originalFileName = res.data?.data[0].originalFileName;
          return res;
        } else {
          this.$_Msg('이미지 업로드 실패 :(');
        }
      } catch (error) {
        this.$log.debug(error);
      }
    },

    onSelectionChanged(e) {
      console.log('eee : ', e);

      if (!e.selectedItem) {
        return;
      }

      const selectedDivisionCd = e.selectedItem.codeId;
      console.log('selectedDivisionCd : ', selectedDivisionCd);

      this.questionDivisionDetailCd = null;

      this.onChangeDivisionType(selectedDivisionCd);
    },

    async onChangeDivisionType(selectedDivisionCd) {
      if (selectedDivisionCd) {
        /* const divisionList = this.$_getCode('ewm_edu_exam_question_division').filter(item => selectedDivisionCd == item.id);
        const codeValue = divisionList[0].codeValue;
        const divisionDetailCdList = this.$_getCode(codeValue); */

        const divisionDetailCdList = this.questionDivisionDetailCdDataSource.filter(d => d.parentId === selectedDivisionCd);

        vm.formData.divisionDetailCdList = divisionDetailCdList;
      }
    },
    /** @description : 코드 로드 */
    async initCodeMap() {
      this.codeMap = await this.$_getCodeMapList('root_ewm_edu_exam_question_division');
    },
    createdData() {
      vm = this;

      this.initCodeMap().then(() => {
        this.questionDivisionCdDataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_exam_question_division'], 2);
        this.questionDivisionDetailCdDataSource = this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_edu_exam_question_division']);

        /* this.dataGridLoopup.questionDivisionDetailCd.dataSource = options => {
          return {
            store: this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_edu_exam_question_division']),
            filter: options.data ? ['parentId', '=', options.data.questionDivisionCd] : null,
          };
        }; */
      });``
    },
    mountData() {
    },
  },
};
</script>

<style lang="scss" >
/* scoped 없이 진행 */
.dx-htmleditor-content img {
  z-index: -1;
}
</style>
<style lang="scss" scoped>
.tableList {
  position:relative;
  width:100%;
  border-top:2px solid #666;
}
.tableList thead tr th {
  //padding: 11px 0;
  border-bottom: 1px solid #ddd;
  font-size: 10px;
  font-weight:normal;
  background:#fff;
  color:#545454;text-align: center;
  min-height: 50px; font-weight: 700;
}
.tableList thead tr:first-child th:first-child {border-left:none;}
.tableList thead tr + tr th {border-top:1px solid #ddd;}
.tableList tbody tr td {
  position:relative;
  border-bottom:1px solid #ddd;
  color:#545454;
  font-size: 11px;
  font-weight:normal;
  text-align:center;
}
.tableList tbody tr th {
  position:relative;
  border-bottom:1px solid #ddd;
  color:#545454;
  font-size: 12px;
  font-weight:normal;
}
.tableList tbody tr td,
.tableList tbody tr td > a,
.tableList tbody tr th > a {font-size:13px;}
.tableList tbody tr:hover td {
  background: #f5f5f5;
}

.p10_20 {
  padding: 8px 35px;
}

.p8 {
  padding: 8px ;
  text-align: left;
}
</style>
